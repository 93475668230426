import React from 'react';
import Layout from 'src/layouts/Page';
import Seo from 'src/components/Seo';
import FaqSection, { FaqTypes } from 'src/sections/shared/Faq';
import HeroSection from 'src/sections/TeamsModule/Hero';
import DetailsSection from 'src/sections/TeamsModule/Details';
import BuiltSection from 'src/sections/TeamsModule/Built';
import GetStarted from 'src/sections/TeamsModule/GetStarted';
import ApiWrapper from 'src/components/ApiWrapper';
import TeamsPricing from 'src/sections/TeamsModule/Pricing';

const TeamsPage: React.FC = () => (
  <ApiWrapper apiServices={['home-api']}>
    <Layout>
      <Seo title="Teams Module" />
      <HeroSection />
      <DetailsSection />
      <BuiltSection />
      <TeamsPricing />
      <FaqSection types={[FaqTypes.TEAMS_MODULE]} />
      <GetStarted />
    </Layout>
  </ApiWrapper>
);

export default TeamsPage;
