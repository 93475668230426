import React from 'react';
import { createUseStyles } from 'react-jss';
import RightLeftChecklistSection from '../shared/RightLeftChecklist';

const useStyles = createUseStyles({
  image: {
    position: 'relative',
    width: 600,
  },
});

const content1 = [
  <>
    <strong>All-in-One Access</strong>
    {' '}
    Provide employees with access to website tools, professional email, and customer lists from a
    single platform. Revoke access anytime.
  </>,
  <>
    <strong>Streamlined Management</strong>
    {' '}
    Keep your team organized by efficiently managing employee information and keeping everything
    up-to-date.
  </>,
  <>
    <strong>Enhanced Productivity</strong>
    {' '}
    Equip your team with the tools they need to perform at their best, all centralized in one place.
  </>,
  <>
    <strong>Secure Information Storage</strong>
    {' '}
    Safeguard employee data with secure storage and easy access, ensuring confidentiality and
    efficiency.
  </>,
];

const BuiltSection: React.FC = () => {
  const classes = useStyles();
  return (
    <RightLeftChecklistSection
      title="Unlock Your Team’s Full Potential"
      rightContent={{
        title: 'Empower Your Team and Boost Efficiency',
        checklist: content1,
        media: (
          <img
            width={600}
            className={classes.image}
            src="https://assets.bizwise.com/landing-ui/product-images/TeamsModuleBuilt1.svg"
            alt="Teams Module"
          />
        ),
      }}
      sectionContainerStyleOverrides={{
        paddingBottom: 0,
      }}
      leftShapeColorOverride="#F5D545"
      contentAlign="start"
    />
  );
};

export default BuiltSection;
