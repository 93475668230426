import React from 'react';
import { createUseStyles } from 'react-jss';
import { spacing, breakpoint, pxToRem } from 'src/theme';
import SectionContainer from 'src/sections/shared/SectionContainer';
import Button from 'src/components/core/Button';
import Typography from 'src/components/core/Typography';

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  button: {
    marginTop: spacing(1),
    marginBottom: spacing(10),
    [breakpoint.down('sm')]: {
      width: '100%',
    },
  },
  secondaryText: {
    margin: spacing(8),
    whiteSpace: 'pre-line',
  },
  icon: {
    alignSelf: 'center',
    height: pxToRem(120),
  },
});

const GetStarted: React.FC = () => {
  const classes = useStyles();
  return (
    <SectionContainer
      className={classes.container}
      backgroundShape={6}
      background="light-blue"
      backgroundShapeBlendMode="overlay"
    >
      <Typography variant="h2" color="blue" underline centered>
        Ready to Streamline Your Team?
      </Typography>
      <Typography variant="p1" color="blackish" centered className={classes.secondaryText}>
        Optimize your team’s efficiency with Bizwise’s Teams module.
        <br />
        Contact us today to get started.
      </Typography>
      <Button
        variant="filled"
        color="teal"
        href="/register/"
        className={classes.button}
      >
        Get started
      </Button>
    </SectionContainer>
  );
};

export default GetStarted;
