import React from 'react';
import VideoContainer from 'src/images/video-containers/Rectangle2.svg';
import AutoplayVideo from 'src/components/AutoplayVideo';
import useResizeObserver from 'use-resize-observer';
import { createUseStyles } from 'react-jss';
import { spacing, breakpoint, pxToRem } from 'src/theme';
import { assetLink } from 'src/utils';
import Typography from 'src/components/core/Typography';
import SectionContainer from '../shared/SectionContainer';

const videoWidthToHeightRatio = 0.65;
const videoHeightOffset = 0.35;
const videoMaxWidth = pxToRem(900);

const verticalLargePadding = `calc(min(${videoMaxWidth}, 50vw) * ${
  videoWidthToHeightRatio
} * ${
  videoHeightOffset
})`;
const verticalSmallPadding = `calc(min(${videoMaxWidth}, 75vw) * ${
  videoWidthToHeightRatio
} * ${
  videoHeightOffset
})`;

export const useSectionAboveStyles = createUseStyles({
  root: {
    paddingBottom: verticalLargePadding,
    [breakpoint.down('md')]: {
      paddingBottom: verticalSmallPadding,
      paddingTop: 0,
    },
  },
});

const useStyles = createUseStyles({
  container: {
    overflow: 'visible',
    background: '#F7F7F7',
    // CSS trick so that we can have a video which is not absolute positioned
    // and still overlap with the above section
    paddingTop: '1px !important',
  },
  grid: {
    display: 'grid',
    justifyContent: 'center',
    columnGap: spacing(10),
    rowGap: spacing(10),
    marginTop: spacing(15),
    gridTemplateColumns: `repeat(3, minmax(0, ${pxToRem(420)}))`,
    [breakpoint.down('sm')]: {
      gridTemplateColumns: 'repeat(1, minmax(0, 1fr))',
      marginTop: spacing(10),
    },
  },
  video: {
    maxWidth: videoMaxWidth,
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '60vw',
    [breakpoint.down('md')]: {
      width: '80vw',
    },
  },
  icon: {
    height: pxToRem(90),
  },
});

const content: {
  iconSrc: string,
  label: string,
  details: string,
}[] = [
  {
    iconSrc: assetLink('landing-ui/icons/TeamsModulePaintIcon.svg'),
    label: 'Centralized Tool Access',
    details: 'Grant your team seamless access to Bizwise’s suite of tools, including website editing, professional email, and customer management.',
  },
  {
    iconSrc: assetLink('landing-ui/icons/TeamsModuleNotificationIcon.svg'),
    label: 'Employee Information',
    details: 'Keep all your employee information organized and easily accessible, ensuring your team’s details are always up-to-date.',
  },
  {
    iconSrc: assetLink('landing-ui/icons/TeamsModuleClickIcon.svg'),
    label: 'Effortless Collaboration',
    details: 'Enhance team collaboration by providing a unified platform where employees can manage their tasks and access the resources they need.',
  },
];

const EmailModuleDetails: React.FC = () => {
  const classes = useStyles();
  const {
    height: videoHeight = 0,
    ref: videoRef,
  } = useResizeObserver<HTMLDivElement>();
  return (
    <SectionContainer className={classes.container}>
      <div className={classes.grid}>
        {content.map(({ iconSrc, label, details }) => (
          <div className="flex flex-col items-center" key={label}>
            <img src={iconSrc} alt={`${label} Icon`} className={classes.icon} />
            <Typography variant="h3" color="blue" centered style={{ marginTop: spacing(6) }}>
              {label}
            </Typography>
            <Typography variant="p2" color="textSecondary" centered style={{ marginTop: spacing(2) }}>
              {details}
            </Typography>
          </div>
        ))}
      </div>
    </SectionContainer>
  );
};

export default EmailModuleDetails;
