import React from 'react';
import { createUseStyles } from 'react-jss';
import {
  spacing, breakpoint, pxToRem, theme,
} from 'src/theme';
import { CheckIcon } from '@heroicons/react/solid';
import Typography from 'src/components/core/Typography';
import SectionContainer from '../shared/SectionContainer';

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    background: 'linear-gradient(180deg, #EBF1FF 50.06%, rgba(235, 241, 255, 0) 100%)',
    paddingTop: spacing(10),
    paddingBottom: spacing(10),
  },
  underline: {
    marginBottom: spacing(10),
    [breakpoint.down('md')]: {
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  pricingBox: {
    display: 'flex',
    marginTop: spacing(10),
    background: 'linear-gradient(109deg, #13266D 0.49%, #00B6B6 130.79%), #4282FF',
    maxWidth: pxToRem(1200),
    boxShadow: '4px 12px 24px rgba(0, 0, 0, 0.2)',
    borderRadius: 20,
    padding: spacing(10),
    [breakpoint.down('md')]: {
      flexDirection: 'column',
    },
  },
  pricingBoxLeft: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  pricingBoxRight: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginTop: spacing(4),
    marginBottom: spacing(4),
  },
  verticalDivider: {
    height: 180,
    width: 1,
    backgroundColor: theme.palette.primary.WHITE,
    marginTop: spacing(4),
    marginBottom: spacing(4),
    marginLeft: spacing(8),
    marginRight: spacing(8),
    [breakpoint.down('md')]: {
      display: 'none',
    },
  },
  check: {
    display: 'inline-block',
    color: theme.palette.primary.TEAL,
    width: pxToRem(24),
    marginRight: spacing(2),
  },
});

const TeamsPricing: React.FC = () => {
  const classes = useStyles();
  return (
    <SectionContainer className={classes.container}>
      <Typography
        variant="h2"
        color="blue"
        underline
        underlineClassName={classes.underline}
      >
        Simple. Fair. Pricing.
      </Typography>
      <Typography variant="h3" color="blue">
        No setup fees, hidden fees, or annual contracts.
      </Typography>
      <div className={classes.pricingBox}>
        <div className={classes.pricingBoxLeft}>
          <Typography variant="h3" color="white" centered>
            Employee Access
          </Typography>
          <Typography variant="h2" color="teal" centered>
            FREE
          </Typography>
          <Typography variant="p3" color="white" centered>
            With Website Subscription
          </Typography>
        </div>
        <div className={classes.verticalDivider} />
        <div className={classes.pricingBoxRight}>
          <Typography variant="p2" color="white">
            <CheckIcon className={classes.check} />
            Access to website editing, professional email, customer list
          </Typography>
          <Typography variant="p2" color="white">
            <CheckIcon className={classes.check} />
            Invite and revoke custom access anytime
          </Typography>
          <Typography variant="p2" color="white">
            <CheckIcon className={classes.check} />
            Secure employee information storage
          </Typography>
          <Typography variant="p2" color="white">
            <CheckIcon className={classes.check} />
            Customer Support
          </Typography>
        </div>
      </div>
    </SectionContainer>
  );
};

export default TeamsPricing;
