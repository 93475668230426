import React from 'react';
import cx from 'clsx';
import { createUseStyles } from 'react-jss';
import { pxToRem, breakpoint, spacing } from 'src/theme';
import SlantedSection from 'src/sections/shared/SlantedSection';
import Typography from 'src/components/core/Typography';
import Button from 'src/components/core/Button';
import NavbarSpacer from 'src/components/Navbar/Spacer';
import { SectionImages } from '../shared/StaticImages';

const useStyles = createUseStyles({
  container: {
    height: pxToRem(700),
    minHeight: pxToRem(700),
    [breakpoint.down('md')]: {
      height: 'auto',
      minHeight: 'auto',
    },
  },
  content: {
    marginLeft: 'auto',
    paddingLeft: spacing(6),
    maxWidth: pxToRem(580),
    [breakpoint.down('md')]: {
      paddingRight: spacing(6),
      paddingTop: spacing(2),
      paddingBottom: pxToRem(200),
      marginLeft: 0,
    },
    [breakpoint.down('sm')]: {
      paddingBottom: pxToRem(60),
    },
    marginBottom: pxToRem(40),
  },
  contentBackground: {
    background: 'radial-gradient(153.67% 510.76% at 153.68% 79.94%, #3D78BB 0%, #13266D 92.07%), #13296B',
    [breakpoint.down('sm')]: {
      background: 'linear-gradient(107.95deg, #13266D -12.75%, #5FBCFF 253.76%), #13296B',
    },
  },
  button: {
    minWidth: pxToRem(180),
    [breakpoint.down('sm')]: {
      width: '100%',
    },
  },
});

const Hero: React.FC = () => {
  const classes = useStyles();
  return (
    <SlantedSection
      img={SectionImages[25]}
      contentWidth={0.6}
      slantAmount={0.25}
      className={classes.container}
      contentClassName={classes.contentBackground}
      showNavbarGradient
    >
      <div className={cx(classes.content)}>
        <NavbarSpacer className="hidden md:block" />
        <Typography variant="h2" color="white" style={{ textDecoration: 'capitalize' }}>
          Enhance your teams ability to help your business
        </Typography>
        <Typography variant="p1" color="white" className="mt-2 mb-4 md:my-6" style={{ maxWidth: pxToRem(570), color: '#FFFFFFB2' }}>
          Empower your employees with easy access to all the essential tools they need,
          from website editing to professional email, all in one place.
        </Typography>
        <Button
          variant="filled"
          color="teal"
          href="/register/"
          className={classes.button}
        >
          Get Started
        </Button>
      </div>
    </SlantedSection>
  );
};

export default Hero;
